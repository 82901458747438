import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { parseBreadCrumbs } from 'epromo-lib/utils/slug';

import { CategoryNavLink } from '@components/molecules/CategoryNavLink';

export type CategoryTreeNavigationProps = {
  flatInventoryList: CategoryTreeItem[];
  paths: string | string[] | undefined;
};

export type CategoryRootOnlyProps = {
  flatInventoryList: CategoryTreeItem[];
  rootElement?: HTMLDivElement | undefined | null;
  isLoading: boolean;
};

export function hoveredCategoryClasses(
  currentCategory: CategoryTreeItem,
  category?: CategoryTreeItem
) {
  const hovered = category && currentCategory.id === category.id;
  return clsx(
    hovered && 'bg-neutral-100 rounded-tl-md rounded-bl-md prod-category'
  );
}

export function CategoryTreeNavigation({
  flatInventoryList,
  paths,
}: CategoryTreeNavigationProps) {
  const router = useRouter();
  const categoryPaths = parseBreadCrumbs(
    flatInventoryList,
    router.locale,
    paths
  );
  const currentCategory = categoryPaths[categoryPaths.length - 1];

  return (
    <nav className="mt-8 flex flex-col px-14">
      <ul role="list" className="flex flex-col">
        {categoryPaths.map((category) => {
          if (!category.childCategories.length && category.level > 1)
            return (
              <ul>
                {flatInventoryList
                  .filter((x) => x.parent === category?.parent)
                  .map((cat) => {
                    return (
                      <li key={`child-${cat.id}`}>
                        <CategoryNavLink
                          key={cat.id}
                          {...cat}
                          currentCategory={currentCategory}
                          isSelected={currentCategory.id === cat.id}
                        />
                      </li>
                    );
                  })}
              </ul>
            );
          return (
            <>
              <li key={`root-${category.id}`}>
                <CategoryNavLink
                  {...category}
                  currentCategory={categoryPaths.find(
                    ({ id }) => id === category.id
                  )}
                  isSelected={currentCategory.id === category.id}
                />
              </li>
              {currentCategory?.id === category.id && (
                <ul>
                  {flatInventoryList
                    .filter((x) => x.parent === currentCategory?.id)
                    .map((cat) => (
                      <li key={`child-${cat.id}`}>
                        <CategoryNavLink
                          key={cat.id}
                          {...cat}
                          currentCategory={currentCategory}
                          isSelected={currentCategory.id === cat.id}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </>
          );
        })}
      </ul>
    </nav>
  );
}
