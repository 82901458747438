import dynamic from 'next/dynamic';

import { IContentLoaderProps } from 'react-content-loader';

const ContentLoader = dynamic(
  import('react-content-loader').then((mod) => mod),
  { ssr: false }
);

interface ISkeletonProps extends IContentLoaderProps {
  isLoaded: boolean;
  children?: React.ReactNode;
  height?: number;
  skeletonTemplate?: React.ReactNode;
}

export const Skeleton = ({
  isLoaded,
  children,
  height = 16,
  skeletonTemplate,
  ...rest
}: ISkeletonProps) => {
  if (isLoaded) return <>{children}</>;

  const resolveSkeletonTemplate = skeletonTemplate ?? (
    <rect id="rect" rx="5" ry="5" width="100%" height={height} />
  );

  return (
    <ContentLoader
      id="loader"
      speed={1.5}
      height={height}
      backgroundColor="#F2F3F7"
      foregroundColor="#fff"
      style={{
        width: '100%',
      }}
      {...rest}
    >
      {resolveSkeletonTemplate}
    </ContentLoader>
  );
};
