import { useRouter } from 'next/router';
import Link from 'next/link';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { resolveLocaleProp } from 'epromo-lib/utils/slug';

import { Icon } from '@components/atoms/Icon';

export const CATEGORY_LEVEL_MAP: Record<string, string> = {
  2: 'mb-3 ml-1.5',
  3: 'pl-6 mb-2 ml-1.5',
  4: 'pl-10 mb-2 ml-1.5',
};

const CATEGORY_LEVEL_FONT_MAP: Record<string, string> = {
  0: 'text-sm font-medium text-secondary-500 py-1',
  1: 'font-bold text-xl leading-6 tracking-wide mb-4',
};

interface CategoryNavLinkProps extends CategoryTreeItem {
  currentCategory?: CategoryTreeItem;
  isSelected?: boolean;
}

export function CategoryNavLink(props: CategoryNavLinkProps) {
  const router = useRouter();
  const localeProp = resolveLocaleProp(router.locale);
  const { currentCategory, isSelected } = props;
  const isCurrent = currentCategory && props.id === currentCategory.id;
  const isRoot = props.level <= 1;
  const showBack = isCurrent && !isRoot && !isSelected;
  return (
    <>
      <Link href={`${props[localeProp]}`} legacyBehavior>
        <a
          className={twMerge(
            clsx(
              'flex w-full items-center text-sm',
              CATEGORY_LEVEL_MAP[props.level],
              CATEGORY_LEVEL_FONT_MAP[props.level],
              isCurrent && 'font-bold'
            ),
            props.level >= 2 && 'hover:text-skin-text-hover'
          )}
        >
          {isRoot && props.webIcon && (
            <img
              src={props.webIcon}
              alt={props.name}
              className={clsx(
                'h-6 w-6 flex-shrink-0',
                props.level === 1 && 'mr-1.5',
                props.level === 0 && 'mr-2'
              )}
            />
          )}
          {showBack && <Icon name={'chevronRight'} className="rotate-180" />}
          <span className={clsx(props.level <= 1 && 'text-[0.8125rem]')}>
            {props.name}
          </span>
        </a>
      </Link>
    </>
  );
}
