import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { clsx } from 'clsx';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { resolveLocaleProp } from 'epromo-lib/utils/slug';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { useBrowser } from 'epromo-lib/hooks/useBrowser';
import { useOnClickOutside } from 'epromo-lib/hooks/useOnClickOutside';

import { CategoryNavLink } from '@components/molecules/CategoryNavLink';
import { SubCategoryColumnsLayout } from '@components/molecules/SubCategoryColumnsLayout';
import {
  CategoryRootOnlyProps,
  hoveredCategoryClasses,
} from '@components/organisms/CategoryTreeNavigation';
import { Skeleton } from '@components/molecules/Skeleton/Skeleton';
import {
  ScrollDownAnimatedGradient,
  useSimpleBarScrollDownAnimation,
} from '@components/atoms/ScrollDownAnimated';

const categoryListWidth = 330;
const subcategoriesSlideoverOffset = categoryListWidth - 4;
// eslint-disable-next-line max-len
const navBaseStyle = `pb-6 pl-12 pt-[20px] h-[600px] w-[${categoryListWidth}px]`;

type MouseCoordinates = {
  x: number;
  y: number;
};

export function CategoryMainNavigation({
  flatInventoryList,
  rootElement,
  isLoading,
}: CategoryRootOnlyProps) {
  const router = useRouter();
  const localeProp = resolveLocaleProp(router.locale);
  const categories = flatInventoryList.filter((cat) => cat.level === 1);
  const [isOpen, setOpen] = useState(false);
  const [category, setCategory] = useState<CategoryTreeItem | undefined>();

  const navRef = useRef<HTMLButtonElement>(null);
  const catListRef = useRef<HTMLOListElement>(null);
  const lastMousePos = useRef<MouseCoordinates>({ x: 0, y: 0 });
  const isMouseInsideExpandedMenu = useRef<boolean>(false);

  const catListOffsetMargin = 5;
  const rootElementHeight = rootElement ? rootElement?.offsetHeight : 'auto';
  const { isSafari } = useBrowser();

  useOnClickOutside(
    navRef,
    () => {
      setOpen(false);
      setCategory(undefined);
      isMouseInsideExpandedMenu.current = false;
    },
    () => {
      return isMouseInsideExpandedMenu.current;
    }
  );

  const debouncedSetCategory = (category: CategoryTreeItem) => {
    if (
      catListRef.current &&
      lastMousePos.current.y > catListRef.current.getBoundingClientRect().bottom
    ) {
      setOpen(false);
      setCategory(undefined);
      return;
    }
    if (
      catListRef.current &&
      lastMousePos.current.y + catListOffsetMargin <
        catListRef.current.getBoundingClientRect().top
    ) {
      setOpen(false);
      setCategory(undefined);
      return;
    }

    if (
      catListRef.current &&
      lastMousePos.current.x + catListOffsetMargin <
        catListRef.current.getBoundingClientRect().left
    ) {
      setOpen(false);
      setCategory(undefined);
      return;
    }
    if (!isMouseInsideExpandedMenu.current) {
      setCategory(category);
    }
    if (!isOpen) {
      setOpen(true);
    }
  };

  const { simpleBarRef, isOverflowing, scrollToBottom, showScrollDownSign } =
    useSimpleBarScrollDownAnimation();

  const Skeletons = () => {
    return (
      <>
        <circle cx="11" cy="12" r="11" />
        <rect x="30" y="4" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="53" r="11" />
        <rect x="30" y="45" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="94" r="11" />
        <rect x="30" y="86" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="135" r="11" />
        <rect x="30" y="127" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="176" r="11" />
        <rect x="30" y="168" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="217" r="11" />
        <rect x="30" y="209" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="258" r="11" />
        <rect x="30" y="250" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="299" r="11" />
        <rect x="30" y="291" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="340" r="11" />
        <rect x="30" y="332" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="381" r="11" />
        <rect x="30" y="373" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="422" r="11" />
        <rect x="30" y="414" rx="8" ry="8" width="221" height="16" />
        <circle cx="11" cy="463" r="11" />
        <rect x="30" y="455" rx="8" ry="8" width="221" height="16" />
      </>
    );
  };

  return (
    <>
      <nav
        ref={navRef}
        className={clsx(
          'relative hidden lg:block',
          navBaseStyle,
          isOpen && 'z-20 rounded-l-lg bg-neutral-100 drop-shadow-md'
        )}
        onMouseLeave={() => {
          if (!isMouseInsideExpandedMenu.current) {
            setOpen(false);
            setCategory(undefined);
          }
        }}
      >
        {isOpen && (
          <div
            className={clsx(
              'absolute top-0',
              'z-20 bg-transparent text-sm text-gray-500',
              isSafari && 'drop-shadow-md'
            )}
            style={{ left: subcategoriesSlideoverOffset }}
            onMouseEnter={() => {
              isMouseInsideExpandedMenu.current = true;
            }}
            onMouseLeave={() => {
              isMouseInsideExpandedMenu.current = false;
            }}
          >
            <div className="relative flex">
              <div
                className={clsx(
                  'w-[500px] xl:w-[952px]',
                  'overflow-hidden',
                  'rounded-r-lg',
                  'bg-neutral-100',
                  'overscroll-contain'
                )}
              >
                <SimpleBar
                  key={category?.id}
                  ref={simpleBarRef}
                  className={clsx('px-8 py-6', isOverflowing && 'pb-14')}
                  style={{
                    height: rootElementHeight,
                  }}
                >
                  <SubCategoryColumnsLayout
                    localeProp={localeProp}
                    category={category}
                  />
                </SimpleBar>
              </div>
              <ScrollDownAnimatedGradient
                isVisible={showScrollDownSign}
                onButtonClick={scrollToBottom}
              />
            </div>
          </div>
        )}
        <Skeleton
          isLoaded={!isLoading}
          height={530}
          style={{
            width: '100%',
            paddingLeft: '0.5rem',
            paddingTop: '0.25rem',
          }}
          skeletonTemplate={<Skeletons />}
        >
          <ol
            ref={catListRef}
            role="list"
            className={clsx(
              'flex flex-col bg-white',
              'absolute left-0 top-0',
              navBaseStyle,
              isOpen && 'z-20 rounded-b-lg rounded-tr-lg'
            )}
            style={{ height: rootElementHeight }}
            onMouseEnter={(evt) => {
              lastMousePos.current = { x: evt.clientX, y: evt.clientY };
            }}
            onMouseLeave={(evt) => {
              lastMousePos.current = { x: evt.clientX, y: evt.clientY };
            }}
          >
            {categories.map((childCategory) => (
              <li
                key={`root-${childCategory.id}`}
                onMouseEnter={(evt) => {
                  lastMousePos.current = { x: evt.clientX, y: evt.clientY };
                  debouncedSetCategory(childCategory);
                }}
                className={clsx(
                  'relative pl-2 pr-4',
                  hoveredCategoryClasses(childCategory, category)
                )}
              >
                <CategoryNavLink {...childCategory} level={0} />
              </li>
            ))}
          </ol>
        </Skeleton>
      </nav>
    </>
  );
}
